<template>
  <v-app>
    <div class="bg-body d-flex flex-column justify-center align-center min-vh-100">
      <div class="sign-up-card">
        <div class="sign-up-card-container">
          <div class="text-center mb-2">
            <h3 class="mb-0">
              <router-link to="/">
                <v-img
                  width="200"
                  src="@/assets/images/logo.png"
                  class="mx-auto"
                />
              </router-link>
            </h3>
          </div>
          <div
            v-if="emailVerified"
            class="text-center mb-10"
          >
            <v-alert
              type="success"
              prominent
            >
              {{ $t("Votre email a été vérifié avec succès. Bienvenue à PLANETE MARKET") }}
            </v-alert>
            <v-row>
              <v-col>
                <v-btn
                  to="/"
                  block
                  color="primary"
                  outlined
                  class="text-capitalize font-600"
                >
                  {{ $t("Continuer les achats") }}
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  to="/edit-profile"
                  block
                  color="primary"
                  class="text-capitalize font-600"
                >
                  {{ $t("Complétez votre profil") }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <div
            v-if="!emailVerified"
            class="text-center mb-10"
          >
            <h3 class="mb-3">
              {{ $t("Verify your email") }}
            </h3>
            <p class="mb-2">
              <v-alert
                type="success"
                prominent
                outlined
              >
                {{ $t("Nous avons envoyé une vérification à l'adresse électronique que vous nous avez fournie. Veuillez vérifier votre courriel pour la vérification.") }} <span class="primary--text">{{ user.email || 'No email' }}</span>
              </v-alert>
            </p>
          </div>

          <v-form
            v-if="!emailVerified"
            ref="registerForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="verifyEmail"
          >
            <div class="mb-4">
              <p class="text-14 mb-1">
                {{ $t('Code de vérification') }}
              </p>
              <v-text-field
                v-model="code"
                placeholder="0000"
                outlined
                :error-messages="errorMessages.code"
                :rules="codeRules"
                :label="$t('Code de vérification')"
                dense
                hide-details="auto"
                class="mb-4"
              />
            </div>

            <div class="mb-10">
              <v-btn
                :disabled="!valid"
                block
                color="primary"
                class="text-capitalize font-600"
                type="submit"
                :loading="loading"
              >
                {{ $t("Continuer") }}
              </v-btn>
            </div>
            <div class="py-2">
              <div class="text-center">
                <v-progress-circular
                  v-if="loadingEmail"
                  indeterminate
                  size="30"
                  color="primary"
                />
                <p
                  v-else
                  class="text-center"
                >
                  {{ $t("Vous n'avez pas reçu de code de vérification ?") }} <span
                    class="cursor-pointer primary--text text--darken-1 font-600"
                    @click.stop="resend"
                  >{{ $t("Resend") }}</span>
                </p>
              </div>
            </div>
          </v-form>
        </div>

        <v-snackbar
          v-model="isSnackbarBottomVisible"
          :color="snackBarColor"
          :light="$vuetify.theme.dark"
          timeout="5000"
        >
          {{ snackBarMessage }}
        </v-snackbar>
      </div>
    </div>
  </v-app>
</template>

<script>
  import { i18n } from '@/plugins/i18n'
  import { mapState } from 'vuex'

  export default {

    data () {
      return {
        user: JSON.parse(localStorage.getItem('userData')),
        code: '',
        emailVerifications: 0,
        isSnackbarBottomVisible: false,
        snackBarMessage: '',
        snackBarColor: 'success',
        valid: false,
        checkbox: false,
        loading: false,
        emailVerified: false,
        loadingEmail: false,
        isPasswordVisible: false,
        isConfirmPasswordVisible: false,
        name: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        confirmPassword: '',
        errorMessages: { name: 'Est requis' },
        termsRules: [v => !!v || i18n.t('Accepter les conditions générales')],
        codeRules: [v => !!v || i18n.t('Le Code de verification est requis')],
        firstNameRules: [v => !!v || i18n.t('Le prénom est requis')],
        lastNameRules: [v => !!v || i18n.t('Le nom de famille est requis')],
        emailRules: [v => !!v || i18n.t('L\'e-mail est obligatoire')],
        passwordRules: [v => !!v || i18n.t('Le mot de passe est requis')],
        confirmPasswordRules: [v => !!v || i18n.t('Le mot de passe de confirmation est requis')],

      }
    },
    computed: {
      ...mapState({
        displayNotification: 'display_notification',
      }),
    },
    created () {
    },
    methods: {
      verifyEmail () {
        const isFormValid = this.$refs.registerForm.validate()

        if (!isFormValid) return

        this.loading = true
        axios({
          method: 'post',
          url: '/verify_code',
          data: { code: this.code },
          validateStatus: (status) => {
            return true
          },
        }).then((response) => {
          if (response.data && response.data.error) {
            this.toast(response.data.message, 'red')
          } else {
            this.emailVerified = true
          }
        }).catch((error) => {
          this.loading = false
        }).then(() => {
          this.loading = false
        })
      },
      resend () {
        if (this.emailVerifications > 3) {
          this.toast(this.$t('Sorry we can no longer send you verification emails.'), 'red')
          return
        }

        this.loadingEmail = true
        axios({
          method: 'post',
          url: '/verify_email',
          data: { },
          validateStatus: (status) => {
            return true
          },
        })
          .then((response) => {
            if (response.data && response.data.error) {
              this.toast(response.data.message, 'red')
            } else {
              this.emailVerifications = parseInt(this.emailVerifications) + 1
              this.codeSent = true
              this.item.verified_email = response.data
            }
          }).then(() => {
            this.loadingEmail = false
          }).catch((error) => {
            this.loadingEmail = false
          })
      },
      toast (message, color) {
        this.isSnackbarBottomVisible = true
        this.snackBarMessage = message
        this.snackBarColor = color
      },
    },
  }
</script>

<style lang="scss" scoped>
.sign-up-card {
  width: 500px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
  margin: 2rem auto;
  box-shadow: rgb(3 0 71 / 9%) 0px 8px 45px;
  @media(max-width: 500px){
    width: 100%;
  }
  .sign-up-card-container {
    padding: 3rem 3.75rem 0px;
    @media(max-width: 500px){
      padding: 3rem 1rem 0px;
    }
  }
}

</style>
